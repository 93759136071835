<template>
  <div class="channel">
    <div v-for="(item, index) in currentChannelList" :key="index" class="channel-item">
      <Row justify="space-between" @click="changeChannel(item.id)">
        <Col>
          <img :src="item?.img" class="pay-icon" />
          <span class="text">{{ item.name }}</span>
        </Col>
        <Col>
          <Radio :value="item.id" :checked="channelId === item.id" />
        </Col>
      </Row>
    </div>
    <Button type="primary" class="to-pay-but" :loading="loadingPaid" @click="toPay()">
      确认支付
    </Button>
  </div>
</template>

<script lang="ts" setup>
import { PropType, onMounted, ref } from 'vue';
import { useOnline } from '@vueuse/core';
import { Row, Col, Radio, message, Button } from 'ant-design-vue';
import { getOrderPayElement, getOrderStatus, getContractStatus, Status } from '@/apis';
import { ISUBMIT_SOURCE, ORDER_STATUS } from '@/apis/common';
import { channelList, IChannelItem, CHANNEL, ORDER_PAGE_STATUS, OrderInfo } from '../utils/config';
import { handleResultStatus } from '../utils/method';
const props = defineProps({
  orderData: {
    type: Object as PropType<OrderInfo>,
    required: true,
  },

  changePaymentStatus: {
    type: Function as PropType<(status: ORDER_PAGE_STATUS) => void>,
    required: true,
  },
});

const {
  orderData: { orderNo, succRedirect, contractNo, failRedirect, channel },
} = props;
const currentChannelList = ref<IChannelItem[]>([]);
const channelId = ref<CHANNEL>(CHANNEL.ALIPAY);
const loadingPaid = ref<boolean>(false);

// 轮询查订单状态，根据支付状态跳转到指定页面
const getOrderStatusFn = async () => {
  //
  try {
    const { data } = await getOrderStatus({
      orderNo,
    });
    if (data) {
      if (data?.orderStatus === ORDER_STATUS.PAID) {
        message.success('订单已支付');
        setTimeout(() => {
          handleResultStatus(succRedirect);
        }, 500);
      } else if (data.orderStatus === ORDER_STATUS.CANCELLED) {
        message.info('订单已取消');
        clearInterval(timer);
      } else {
        // 正常时做什么操作
        loadingPaid.value = false;
      }
    }
  } catch (error) {
    clearInterval(timer);
  }
};

// 请求查询签约状态
const getContractStatusFn = async () => {
  try {
    const { data } = await getContractStatus({
      contractNo,
    });
    if (data) {
      if (data?.status === Status.SIGNED || data?.status === Status.EFFECTIVE) {
        message.success('已成功签约');
        setTimeout(() => {
          handleResultStatus(succRedirect);
        }, 500);
      } else if (data?.status === Status.PRE_SIGNING) {
        loadingPaid.value = false;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const timer = setInterval(orderNo !== 'null' ? getOrderStatusFn : getContractStatusFn, 2000);

// 切换渠道
const changeChannel = (val: CHANNEL) => {
  channelId.value = val;
  loadingPaid.value = false;
};

// 容错处理：接口报错 / 异常 / 无网络等情况
const handleOrderErrorStatus = () => {
  clearInterval(timer);
  const online = useOnline();
  if (online.value) {
    // 跳失败页面
    message.error('当前订单已取消无法继续支付，请重新下单！');
    handleResultStatus(failRedirect);
  } else {
    //  error 页面
    message.error('网络异常，请检查网络状态');
  }
};

// 根据订单信息和选择渠道去获取跳转链接
const toPay = async () => {
  loadingPaid.value = true;
  const currentUrl = window.location.href;
  const encodeUrl = encodeURIComponent(currentUrl);
  const params = {
    channel: channelId.value,
    orderNo,
    contractNo,
    submitSource: ISUBMIT_SOURCE.H5,
    returnUrl: currentUrl,
  };

  try {
    const { data } = await getOrderPayElement(params);
    if (data) {
      const {
        channelFactor: { codeUrl },
      } = data;
      // 跳转中间页发起支付(微信需要拼接redirect_url)
      const redirectUrl =
        channelId.value === CHANNEL.WECHAT ? `${codeUrl}&redirect_url=${encodeUrl}` : codeUrl;
      window.location.href = redirectUrl;
    }
  } catch (error: any) {
    // 容错处理：接口报错
    if (error.code === 20001) {
      handleResultStatus(succRedirect);
    } else if (error.code === 20002) {
      handleResultStatus(failRedirect);
    } else {
      handleOrderErrorStatus();
    }
  }
};

onMounted(() => {
  // 组装渠道列表
  currentChannelList.value = channelList.filter((item1) => {
    return channel?.some((item2) => item2.channelCode === item1.id);
  });
});
</script>

<style scoped lang="less">
.channel {
  width: 100%;
  margin-top: 49px;
  // height: calc(100% - 200px);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.channel-item {
  width: calc(100% - 38px);
  height: 69px;
  line-height: 69px;
  margin: 0 auto;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
  }

  .pay-icon {
    width: 26px;
    margin-left: 9px;
    margin-top: -7px;
  }
  .text {
    font-size: 17px;
    margin-left: 11px;
    margin-top: -2px;
  }
}
.to-pay-but {
  width: calc(100% - 44px);
  height: 47px;
  color: #ffffff;
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
  background: #527de2;
  border-radius: 4px;
  cursor: pointer;
}
</style>
