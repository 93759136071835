<!--错误页面-->
<template>
  <div :class="getClassNames">
    <img :src="getImgSrc" alt="无网络" class="nowifi-icon" />

    <div class="text">{{ errorText }}</div>
    <div class="reload" @click="reload"><ReloadOutlined /><span class="text">重新刷新</span></div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { ReloadOutlined } from '@ant-design/icons-vue';
import { ISUBMIT_SOURCE } from '@/apis/common';
import pc_nowifi from '@/assets/img/n_wifi1.png';
import mobile_nowifi from '@/assets/img/n_wifi.png';

const props = defineProps({
  errorText: {
    type: String,
    required: true,
  },
  platform: {
    type: String,
    required: true,
    default: ISUBMIT_SOURCE.PC,
  },
});

const getClassNames = computed(() => {
  return props.platform === ISUBMIT_SOURCE.PC ? 'desktop' : 'mobile';
});

const getImgSrc = computed(() => {
  return props.platform === ISUBMIT_SOURCE.PC ? pc_nowifi : mobile_nowifi;
});

const reload = () => {
  location.reload();
};
</script>
<style lang="less" scoped>
.desktop {
  width: 1200px;
  text-align: center;
  margin: 78px auto 0 auto;

  .nowifi-icon {
    width: 40px;
    margin-bottom: 12px;
  }
  .text {
    color: #656665;
  }
  .reload {
    cursor: pointer;
    color: rgb(115, 115, 247);
    margin-top: 36px;
    .text {
      margin-left: 6px;
      color: #527de2;
    }
  }
}

.mobile {
  width: 100%;
  text-align: center;
  margin: 46px auto 0 auto;
  .nowifi-icon {
    width: 30px;
    margin-bottom: 12px;
  }
  .text {
    color: #656665;
  }
  .reload {
    cursor: pointer;
    color: rgb(115, 115, 247);
    margin-top: 40px;
    .text {
      margin-left: 5px;
      color: #527de2;
    }
  }
}
</style>
