<template>
  <Row align="middle">
    <Col> <img :src="getCurrentchannelType(currentChannel)?.img" class="pay-icon" /></Col>
    <Col class="payment-title">
      {{ shortText }}
    </Col>
    <Col class="change-type" @click="changeChannel">切换其他支付方式</Col>
  </Row>

  <!-- 二维码支付 -->
  <div v-if="codeurl" class="qrcode-box">
    <img :src="codeurl" width="200" height="200" />
    <div v-if="isTimeout" class="mask"></div>
    <div v-if="isTimeout || !codeurl" class="mask-text">二维码已失效</div>
    <div v-if="currentChannel === CHANNEL.ALL">
      <div>
        可以用以下渠道完成支付：<span style="font-weight: 500"
          >微信、支付宝、云闪付、主流银行app</span
        >
      </div>
    </div>
  </div>

  <!-- 非二维码支付 -->
  <div v-else-if="!isHasCode" class="payment-info">
    <div>请在新打开的网银页面完成支付</div>
    <div>支付状态确认中 <LoadingOutlined /></div>
  </div>

  <!-- 接口异常，报错情况 -->
  <div v-else class="qrcode-loading">
    <LoadingOutlined v-if="!orderErrorStatus" />
    <div class="text">{{ errorText }}</div>
  </div>
</template>

<script lang="ts" setup>
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, onMounted, watchEffect, PropType, onUnmounted, computed } from 'vue';
import { Row, Col, message } from 'ant-design-vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import QRCode from 'qrcode';
import { useOnline } from '@vueuse/core';
import { ISUBMIT_SOURCE, ORDER_STATUS } from '@/apis/common';
import { getOrderStatus, getOrderPayElement, getContractStatus, Status } from '@/apis';
import { ORDER_PAGE_STATUS, OrderInfo, CHANNEL } from '../utils/config';
import { getCurrentchannelType, handleResultStatus } from '../utils/method';

const props = defineProps({
  orderData: {
    type: Object as PropType<OrderInfo>,
    required: true,
  },
  isTimeout: {
    type: Boolean,
    required: true,
  },
  currentChannel: {
    type: Number,
    required: true,
  },
  changePaymentStatus: {
    type: Function as PropType<(status: ORDER_PAGE_STATUS) => void>,
    required: true,
  },
});
const {
  orderData: { orderNo, failRedirect, contractNo, succRedirect },
  isTimeout,
  currentChannel,
  changePaymentStatus,
} = props;

const codeurl = ref<string>('');
const errorText = ref<string>('二维码生成中...');
const orderErrorStatus = ref<boolean>(false);

// 处理支付时的提示文案
const shortText = computed(() => {
  const shortName = getCurrentchannelType(currentChannel)?.shortName;
  const hasQrcode = getCurrentchannelType(currentChannel)?.hasQrcode;

  if (hasQrcode) {
    return shortName + '扫码支付';
  }
  return shortName + '跳转支付';
});

// 是否是二维码支付
const isHasCode = computed(() => {
  if (
    currentChannel === CHANNEL.COMPANY_ONLINE_BANKING ||
    currentChannel === CHANNEL.SELF_ONLINE_BANKING
  ) {
    return false;
  }
  return true;
});

const generateQR = async (text: string) => {
  try {
    codeurl.value = await QRCode.toDataURL(text);
  } catch (err) {
    errorText.value = '二维码生成失败，请刷新后重新生成';
    // console.error(err);
  }
};

// 切换渠道
const changeChannel = () => {
  changePaymentStatus(ORDER_PAGE_STATUS.UNPAID);
};

// 轮询查订单状态
const getOrderStatusFn = async () => {
  // 请求查询订单状态，根据支付状态跳转到指定页面
  try {
    const { data } = await getOrderStatus({
      orderNo,
    });
    if (data) {
      if (data?.orderStatus === ORDER_STATUS.PAID) {
        message.success('订单已支付');
        setTimeout(() => {
          changePaymentStatus(ORDER_PAGE_STATUS.PAID);
        }, 500);
      } else if (data.orderStatus === ORDER_STATUS.CANCELLED) {
        message.info('订单已取消');
        setTimeout(() => {
          handleResultStatus(failRedirect);
        }, 500);
      } else {
        // 未支付
      }
    }
  } catch (error) {
    clearInterval(timer);
  }
};

// 请求查询签约状态
const getContractStatusFn = async () => {
  try {
    const { data } = await getContractStatus({
      contractNo,
    });
    if (data) {
      if (data?.status === Status.SIGNED || data?.status === Status.EFFECTIVE) {
        message.success('已成功签约');
        setTimeout(() => {
          handleResultStatus(succRedirect);
        }, 500);
      } else if (data?.status === Status.PRE_SIGNING) {
        //  正常未签约
      }
    }
  } catch (error) {
    console.error(error);
  }
};

// 开启定时器
const timer = setInterval(orderNo !== 'null' ? getOrderStatusFn : getContractStatusFn, 2000);

// 容错处理：接口报错 / 异常 / 无网络等情况
const handleOrderErrorStatus = () => {
  clearInterval(timer);
  const online = useOnline();
  orderErrorStatus.value = true;
  if (online.value) {
    errorText.value = '当前订单已取消无法继续支付，请重新下单！';
  } else {
    errorText.value = '网络异常，请检查网络状态';
  }
};

// 根据订单信息和选择渠道去获取生成的二维码
const getOrderInfo = async () => {
  const params = {
    channel: currentChannel,
    orderNo,
    contractNo,
    submitSource: ISUBMIT_SOURCE.PC,
  };

  try {
    const { data } = await getOrderPayElement(params);
    if (data) {
      const {
        channelFactor: { codeUrl },
      } = data;
      if (isHasCode.value) {
        codeUrl ? generateQR(codeUrl) : clearInterval(timer);
      } else {
        // 跳转个人网银和企业网银
        window.open(codeUrl, '_blank');
      }
    }
  } catch (error: any) {
    // 容错处理：接口报错
    if (error.code === 20001) {
      handleResultStatus(succRedirect);
    } else if (error.code === 20002) {
      handleResultStatus(failRedirect);
    } else {
      handleOrderErrorStatus();
    }
  }
};

onMounted(() => {
  getOrderInfo();
});

onUnmounted(() => {
  clearInterval(timer);
});

watchEffect(() => {
  if (isTimeout) {
    clearInterval(timer);
    generateQR('timeout');
  }
});
</script>

<style lang="less" scoped>
.pay-icon {
  width: 50px;
}
.qrcode-loading {
  text-align: center;
  .text {
    margin-top: 10px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #656665;
  }
}

.qrcode-box {
  position: relative;
  width: 200px;
  overflow: hidden;
  left: 50%;
  margin-left: -100px;

  .mask {
    width: 160px;
    height: 160px;
    background: #575757;
    position: absolute;
    top: 21px;
    left: 21px;
    opacity: 0.8;
  }
  .mask-text {
    width: 160px;
    height: 160px;
    font-size: 16px;
    color: white;
    line-height: 150px;
    text-align: center;
    position: absolute;
    top: 25px;
    left: 25px;
  }
}

.payment-info {
  font-size: 14px;
  text-align: center;
  margin: 50px;
}

.payment-title {
  font-size: 20px;
  margin-left: 16px;
}
.change-type {
  font-size: 16px;
  cursor: pointer;
  color: #527de2;
  margin-left: 40px;
}
</style>
