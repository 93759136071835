<template>
  <Dialogue
    v-if="orderStatus === ORDER_PAGE_STATUS.DIALOGUE"
    :changePaymentStatus="changePaymentStatus"
  />

  <Market
    v-if="orderStatus === ORDER_PAGE_STATUS.MARKET"
    :changePaymentStatus="changePaymentStatus"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Dialogue from './Dialogue.vue';
import Market from './Market.vue';
import { ORDER_PAGE_STATUS } from './config';

const orderStatus = ref(ORDER_PAGE_STATUS.DIALOGUE);

// 切换页面的事件
const changePaymentStatus = (status: ORDER_PAGE_STATUS) => {
  orderStatus.value = status;
};
</script>

<style scope lang="less">
.box {
  height: 100%;
  margin-left: 20px;
  margin-right: 30px;
}
.market {
  cursor: pointer;
}
</style>
