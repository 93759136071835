import alipayicon from '@/assets/img/alipayicon.png';
import wechaticon from '@/assets/img/wechaticon.png';
import { ChannelItem, ISUBMIT_SOURCE, CHANNEL } from '@/apis/common';

enum ORDER_PAGE_STATUS {
  UNPAID,
  INPAYMENT,
  PAID,
}

interface OrderInfo {
  /**
   * 订单号
   */
  orderNo: string;

  /**
   * 用户id
   */
  uid: string;

  /**
   * 业务方标识
   */
  appKey: string;

  /**
   * 成功跳转
   */
  succRedirect: string;

  /**
   * 失败跳转
   */
  failRedirect: string;

  /**
   * 确认中跳转
   */
  confirmingRedirect: string;

  /**
   * 唯一的随机串
   */
  apiTempToken: string;

  /**
   * 商品名
   */
  goods: string;
  /**
   * 价格
   */
  price: string;
  /**
   * 支持的支付渠道
   */
  channel: ChannelItem[];
  /**
   * 最晚支付时间
   */
  timeleft: number;
  /**
   * 当前服务器时间
   */
  currentTime: number;
}

interface IOrderParams {
  platform: ISUBMIT_SOURCE;
  orderNo: string;
  appKey: string;
  failRedirect: string;
  succRedirect: string;
}

interface IChannelItem {
  name: string;
  shortName: string;
  id: CHANNEL;
  img: string;
  hasQrcode: boolean;
}

const channelList: IChannelItem[] = [
  {
    name: '微信支付',
    shortName: '微信',
    id: CHANNEL.WECHAT,
    img: wechaticon,
    hasQrcode: true,
  },
  {
    name: '支付宝支付',
    shortName: '支付宝',
    id: CHANNEL.ALIPAY,
    img: alipayicon,
    hasQrcode: true,
  },
  // 联调时，修改id值
  {
    name: '聚合支付',
    shortName: '聚合',
    id: CHANNEL.ALL,
    img: alipayicon,
    hasQrcode: true,
  },
  {
    name: '企业网银',
    shortName: '企业网银',
    id: CHANNEL.COMPANY_ONLINE_BANKING,
    img: alipayicon,
    hasQrcode: false,
  },
  {
    name: '个人网银',
    shortName: '个人网银',
    id: CHANNEL.SELF_ONLINE_BANKING,
    img: alipayicon,
    hasQrcode: false,
  },
];

export { ORDER_PAGE_STATUS, IChannelItem, CHANNEL, OrderInfo, IOrderParams, channelList };
