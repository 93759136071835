enum ORDER_STATUS {
  NEW,
  PAID,
  CANCELLED,
}

enum PAYMENT_STATUS {
  UNPAID,
  PAID,
  REFUNDING,
  REFUNDED,
  REFUND_FAILE,
}

enum ORDER_TYPE {
  STANDARD = 'Standard',
  GROUPON = 'Groupon',
}

enum MEDIA_TYPE {
  AUDIO,
  VIDEO,
}

enum ISUBMIT_SOURCE {
  PC = 'pc',
  H5 = 'h5',
}

enum CHANNEL {
  // 微信
  WECHAT = 2,
  // 支付宝
  ALIPAY = 3,
  // 聚合支付
  ALL = 12,
  // 个人网银
  SELF_ONLINE_BANKING = 13,
  // 企业网银
  COMPANY_ONLINE_BANKING = 14,
}

interface ChannelItem {
  channelCode: CHANNEL;
}

export {
  MEDIA_TYPE,
  ORDER_TYPE,
  PAYMENT_STATUS,
  ORDER_STATUS,
  ISUBMIT_SOURCE,
  ChannelItem,
  CHANNEL,
};
