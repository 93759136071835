<template>
  <ConfigProvider :prefixCls="antdPrefixCls">
    <CashierCounter
      v-if="orderNo || contractNo"
      :hasHeader="true"
      :platform="platform"
      :orderNo="orderNo"
      :contractNo="contractNo"
      :appKey="appKey"
      :failRedirect="failRedirect"
      :succRedirect="succRedirect"
    />

    <Pay v-else />
  </ConfigProvider>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import { ConfigProvider, message } from 'ant-design-vue';
import { antdPrefixCls } from '@/constant';
import CashierCounter from '@/components/CashierCounter/index.vue';
import Pay from '@/components/Pay/index.vue';
import { getSearchParams } from '@/utils/location';
import { IOrderParams } from '@/components/CashierCounter/utils/config';
import { ISUBMIT_SOURCE } from '@/apis/common';

ConfigProvider.config({
  prefixCls: antdPrefixCls,
});

message.config({
  top: `90px`,
});
const orderData = ref<IOrderParams>();

// const hasHeader = computed(() => {
//   return orderData.value?.hasHeader;
// });

const platform = computed(() => {
  return orderData.value?.platform || ISUBMIT_SOURCE.PC;
});

const orderNo = computed(() => {
  return orderData.value?.orderNo;
});

const contractNo = computed(() => {
  return orderData.value?.contractNo;
});

const appKey = computed(() => {
  return orderData.value?.appKey || '';
});

const failRedirect = computed(() => {
  return orderData.value?.failRedirect || '';
});

const succRedirect = computed(() => {
  return orderData.value?.succRedirect || '';
});

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const query = getSearchParams() as any as IOrderParams;
  orderData.value = query;
});
</script>

<style lang="less">
#app {
  height: 100%;
}
.text-input {
  width: 300px;
  height: 500px;
  border: 1px solid;
}
</style>
