import { getReasonPhrase } from 'http-status-codes';

export const statusMessageDict: Record<number, string> = {
  400: '错误请求',
  401: '登录过期，请重新登录',
  403: '没有相关权限，请联系管理员',
  404: '未找到该资源',
  405: '请求方法未允许',
  408: '请求超时',
  429: '请求频繁',
  500: '服务器内部错误',
  501: '网络未实现',
  502: '网络错误',
  503: '服务不可用',
  504: '网络连接错误，请稍候重试',
  505: '不支持该请求',
};

/**
 * @description 根据状态码获取错误信息
 * @param {number} status
 * @returns {string}
 */
export const getStatusMessage = (status: number): string => {
  if (status === 200) {
    return '请求数据返回成功';
  }
  return statusMessageDict[status] || getReasonPhrase(status);
};
