<template>
  <!-- 顶部订单状态 -->
  <div v-if="orderStatus !== ORDER_PAGE_STATUS.PAID">
    <div class="order-info">
      <div class="order-id" v-if="showOrderNo">订单号：{{ orderData?.orderNo }}</div>
      <div class="order-id" v-else-if="orderData?.contractNo">
        签约号：{{ orderData?.contractNo }}
      </div>

      <span class="amount-unit">
        ¥
        <span class="amount"> {{ orderData?.price }}</span>
      </span>

      <div class="order-last-time">
        剩余支付时间：
        <Countdown
          :orderData="orderData"
          :fontSize="20"
          :timeText="timeText"
          :lastTime="orderData?.timeleft"
          :currentTime="orderData?.currentTime"
          :changeTimeoutStatus="changeTimeoutStatus"
          :changePaymentStatus="changePaymentStatus"
        />
      </div>
    </div>
  </div>

  <!-- 中间内容 -->
  <!-- 未支付 -->
  <Unpaid
    v-if="orderStatus === ORDER_PAGE_STATUS.UNPAID"
    :orderData="orderData"
    :changePaymentStatus="changePaymentStatus"
  />

  <div v-else>订单状态异常，请刷新重新查询订单状态</div>
</template>
<script lang="ts" setup>
import { ref, PropType, computed } from 'vue';
import Unpaid from './Unpaid.vue';
import Countdown from '../widget/Countdown.vue';
import { ORDER_PAGE_STATUS, CHANNEL, OrderInfo } from '../utils/config';

const props = defineProps({
  orderData: {
    type: (Object as PropType<OrderInfo>) || undefined,
    required: true,
  },
  timeText: {
    type: String,
    required: true,
  },
});
const showOrderNo = computed(() => {
  return props.orderData?.orderNo && props.orderData.orderNo !== 'null';
});
const orderStatus = ref<ORDER_PAGE_STATUS>(ORDER_PAGE_STATUS.UNPAID);
const isTimeout = ref<boolean>(false);
const currentChannel = ref<CHANNEL>(CHANNEL.WECHAT);
const qrcodeUrl = ref<string>('');

// 切换页面的事件
const changePaymentStatus = (status: ORDER_PAGE_STATUS, channel?: CHANNEL, codeUrl?: string) => {
  orderStatus.value = status;
  channel ? (currentChannel.value = channel) : null;
  codeUrl ? (qrcodeUrl.value = codeUrl) : null;
};

// 订单超时状态，切换全局状态
const changeTimeoutStatus = (status: boolean) => {
  isTimeout.value = status;
};
</script>
<style lang="less" scoped>
.order-info {
  width: 100%;
  margin: 20px auto 0 auto;
  text-align: center;
  .order-id {
    height: 19px;
    line-height: 19px;
    font-size: 13px;
    color: #909399;
  }
  .amount-unit {
    font-size: 22px;
    margin-left: 8px;
    color: #303133;
  }
  .amount {
    font-size: 40px;
    margin-right: 8px;
    color: #303133;
  }

  .order-last-time {
    height: 21px;
    line-height: 21px;
    font-size: 15px;
    margin-top: 14px;
  }
}

.order-content {
  width: 100%;
}
</style>
