import { Axios } from '@/request/axios';
import { ResponseResult } from '@/request/types';

interface IRequestOrderStatus {
  contractNo: string;
}

enum Status {
  // 已解约
  CONTRACT_TERMINATED = -3,
  // 已退订
  UN_SUBSCRIBED = -1,
  // 预签约
  PRE_SIGNING = 0,
  // 已签约
  SIGNED = 1,
  // 已生效
  EFFECTIVE = 2,
}

interface IResponseOrderStatus {
  appKey: string;
  userId: number;
  ssoId: string;
  channel: string;
  contractNo: string;
  contractTime: string;
  contractRescindTime: string;
  amount: number;
  firstGoodsId: string;
  goodsName: string;
  status: Status;
  nextPay: string;
}

// 获取订单状态
const getContractStatus = async (
  params: IRequestOrderStatus,
): Promise<ResponseResult<IResponseOrderStatus>> => {
  const data: ResponseResult<IResponseOrderStatus> = await Axios.get('/contract/detail', params);
  return data;
};

export { getContractStatus, Status };
