import { Axios } from '@/request/axios';
import { ResponseResult } from '@/request/types';
import { ChannelItem } from './common';
import { stringify } from 'querystring';

interface IOrderInfo {
  [key: string]: string | undefined;
  orderNo: string;
  submitSource: string;
  succRedirect?: string;
  failRedirect?: string;
  confirmingRedirect?: string;
}

interface IGetUnpaidInfoResponse {
  appKey: string;
  channel: ChannelItem[];
  confirmingRedirect: string;
  currentTime: number;
  failRedirect: string;
  orderNo: string;
  price: 1;
  succRedirect: string;
  timeleft: number;
  uid: number;
  url: string;
}

// 获取订单详情
const getUnpaidInfo = async (
  params: IOrderInfo,
): Promise<ResponseResult<IGetUnpaidInfoResponse>> => {
  const paramString = stringify(params);
  const data: ResponseResult<IGetUnpaidInfoResponse> = await Axios.post(
    `/order-center/pay/cashier/pcUrl?${paramString}`,
  );
  return data;
};

export { getUnpaidInfo, IGetUnpaidInfoResponse };
