import { channelList, IChannelItem, CHANNEL } from './config';
import { IGetUnpaidInfoResponse } from '@/apis/getUnpaidInfo';

// 处理金额数据，添加分位显示
const handleThousands = (amount: string) => {
  let num;
  const value = parseFloat(amount).toFixed(2);

  if (value.indexOf('.') === -1) {
    num = value.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`);
  } else {
    num = value.replace(/(\d)(?=(\d{3})+\.)/g, (s) => `${s},`);
  }

  return num;
};
/**
 *
 * @param amountValue 需要格式化的数值
 * @param isCents 默认接收数据是以'分'为单位，如果数据是以'元'为单位，传第二个字段为'false'即可
 */

const handleAmount = (amountValue = 0, isCents = true): number | string => {
  if (amountValue) {
    const normalAmountValue = isCents ? amountValue / 100 : amountValue;

    return handleThousands(String(normalAmountValue));
  }
  return 0;
};

// 解析后端给的参数，处理成自己想要的结构
const handleUrlParams = (data: IGetUnpaidInfoResponse) => {
  return {
    ...data,
    orderNo: String(data.orderNo),
    price: handleAmount(data.price),
  };
};

const channelTagConfig: Map<CHANNEL, IChannelItem> = new Map(
  channelList.map((item) => [item.id, item]),
);
// pick 出当前的channel
const getCurrentchannelType = (value: CHANNEL) => {
  return channelTagConfig.get(value);
};

/** 计算倒计时
 *
 * @param {number} remainingTime 剩余时间
 * @return {string} 返回一个结构性字符串 ： X时X分X秒，如果时间里days>=1，则将天折算到小时里
 */
const getCurrentTime = (remainingTime: number) => {
  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24)); // 天
  const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + days * 24; // 小时
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)); // 分钟
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000); // 秒

  const currentHours = String(hours).length === 1 ? '0' + hours : hours;
  const currentMinutes = String(minutes).length === 1 ? '0' + minutes : minutes;
  const currentSeconds = String(seconds).length === 1 ? '0' + seconds : seconds;

  return currentHours + ':' + currentMinutes + ':' + currentSeconds + '';
};

// 处理当成功或者失败跳转到指定页面，如果没有回调地址，则关闭收银台页面
// （关闭的前提是前一个页面是新打开的）
const handleResultStatus = (url: string) => {
  if (url) {
    window.location.href = url;
  } else {
    window.close();
    // const previousURL = document.referrer;
    // console.log(previousURL, '上一个跳转过来的地址');
  }
};

export { getCurrentchannelType, handleUrlParams, getCurrentTime, handleResultStatus };
