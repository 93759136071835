/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import {
  ResponseResult,
  AxiosErrorResult,
  AxiosBaseOptions,
  RequestHeaders,
  AxiosRequestBaseConfig,
  AxiosResponseResult,
  FORBIDDEN_RESPONSE_TYPES,
  DEFAULT_SUCCESS_CODE,
  DEFAULT_ERROR_CODE,
} from './types';
import { getStatusMessage } from './httpError';
import { useOnline } from '@vueuse/core';
import { message as Message } from 'ant-design-vue';

// axios 主体
export class AxiosBase {
  public options: AxiosBaseOptions;

  public instance: AxiosInstance;

  constructor(options: AxiosBaseOptions) {
    this.options = options;
    this.instance = Axios.create(options);

    this.instance.interceptors.request.use((config: any) => {
      const timestamp = Date.now().toString();

      const requestHeaders = {
        'request-timestamp': timestamp,
      };
      const extraHeaders = this.options.getExtraHeaders();
      const headers: RequestHeaders = config.anonymous
        ? requestHeaders
        : { ...requestHeaders, ...extraHeaders };

      config.headers = { ...config.headers, ...headers };
      config.params = { ...config.params, timestamp };
      this.options.onBeforeStart?.(config);
      return config;
    });

    this.instance.interceptors.response.use(
      (response: AxiosResponse<AxiosResponseResult>) => {
        // 结构化数据
        if (FORBIDDEN_RESPONSE_TYPES.includes(response.config.responseType)) {
          if (response.status === 200) {
            const result: AxiosResponseResult = {
              axiosConfig: response.config,
              code: DEFAULT_SUCCESS_CODE,
              data: response.data,
              message: getStatusMessage(200),
            };
            response.data = result;
            return Promise.resolve(response);
          }
          // 非结构化数据
          const errorMessage = '非结构化数据异常';
          const error: AxiosErrorResult = {
            ...new Error(errorMessage),
            axiosConfig: response.config,
            code: DEFAULT_SUCCESS_CODE,
            data: {},
            message: errorMessage,
          };

          Message.error(errorMessage);
          return Promise.reject(error);
        }
        const { data, code, message } = response.data;

        // 业务逻辑 => 正常
        const isOk = [0].includes(code);

        if (isOk) {
          const result: AxiosResponseResult = {
            axiosConfig: response.config,
            code: DEFAULT_SUCCESS_CODE,
            data: data ?? {},
            message: message ?? getStatusMessage(200),
          };
          response.data = result;

          return Promise.resolve(response);
        }

        // 业务逻辑 => 异常 （状态码非 0 的情况）
        const errorMessage = message ?? '请求异常';
        const error: AxiosErrorResult = {
          ...new Error(errorMessage),
          axiosConfig: response.config,
          code: code ?? DEFAULT_ERROR_CODE,
          data: data ?? {},
          message: errorMessage,
        };

        Message.error(errorMessage);
        return Promise.reject(error);
      },
      (axiosError: AxiosError<AxiosResponseResult>) => {
        // 没网啦 / 请求异常 / 请求超时

        const online = useOnline();

        if (!online.value || !axiosError.response) {
          const errorMessage = '网络异常';
          const error = {
            ...new Error(errorMessage),
            axiosConfig: axiosError.config,
            code: DEFAULT_ERROR_CODE,
            data: {},
            message: errorMessage,
            status: 408,
          };

          // Message.error(errorMessage);
          return Promise.reject(error);
        }

        // 请求错误 && 其实不需要关注业务逻辑，错误码参考 httpError 列表
        const httpStatus = axiosError.response.status;
        const { data, code, message } = axiosError.response.data;

        const errorData = axiosError.response.data;

        const errorMessage = message ?? getStatusMessage(httpStatus);
        const error: AxiosErrorResult = {
          ...new Error(errorMessage),
          axiosConfig: axiosError.config || { hideError: false },
          code: code ?? httpStatus ?? DEFAULT_ERROR_CODE,
          data: data ?? {},
          message: errorMessage,
        };

        Message.error(errorMessage);
        return Promise.reject(error);
      },
    );
  }

  /**
   * @description 任意类型请求包装 https://github.com/axios/axios
   * @template T
   * @template R
   * @param {string} url
   * @param {AxiosRequestBaseConfig} [config]
   * @returns {Promise<R>}
   * @memberof AxiosBase
   */
  private async requestWithLogger<T>(config: AxiosRequestBaseConfig): Promise<ResponseResult<T>> {
    const requestTime = Date.now();

    try {
      const response = await this.instance.request<ResponseResult<T>>(config);
      const { code, message } = response.data;
      this.options.onLogger?.(response.config, {
        code,
        message: encodeURIComponent(message),
        requestTime,
        responseStatus: code,
      });
      return response.data;
    } catch (error) {
      const { axiosConfig, code, message } = error as AxiosErrorResult;
      if (!this.options?.hideError && !axiosConfig?.hideError && this.options?.onError) {
        // @ts-ignore
        this.options?.onError?.(error);
      }
      this.options.onLogger?.(axiosConfig, {
        code,
        message: encodeURIComponent(message),
        requestTime,
        responseStatus: code,
      });

      throw error;
    }
  }

  // ---------- 下面为具体封装的不同请求方式的接口 ----------

  /**
   *
   * @param {string} url 请求地址
   * @param {T} params 参数
   * @param {AxiosRequestBaseConfig} config 其他特殊配置
   * @returns
   */
  public async get<T>(
    url: string,
    params?: any,
    config?: AxiosRequestBaseConfig,
  ): Promise<ResponseResult<T>> {
    return await this.requestWithLogger<T>({
      ...config,
      params,
      method: 'GET',
      url,
    });
  }

  /**
   *
   * @param {string} url 请求地址
   * @param {T} data 参数
   * @param {AxiosRequestBaseConfig} config 其他特殊配置
   * @returns
   */
  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestBaseConfig,
  ): Promise<ResponseResult<T>> {
    return await this.requestWithLogger<T>({
      ...config,
      data,
      method: 'POST',
      url,
    });
  }

  /**
   *
   * @param {string} url 请求地址
   * @param {AxiosRequestBaseConfig} config 其他特殊配置
   * @returns
   */
  public async delete<T>(url: string, config?: AxiosRequestBaseConfig): Promise<ResponseResult<T>> {
    return await this.requestWithLogger<T>({
      ...config,
      method: 'DELETE',
      url,
    });
  }
}
