<!--顶部header-->
<template>
  <div class="desktop">
    <Row class="content">
      <Col><img :src="currentLogo" class="logo" /></Col>
      <Col> <div class="text">收银台</div></Col>
    </Row>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Row, Col } from 'ant-design-vue';
import unipuslogo from '@/assets/img/logo.png';

const props = defineProps({
  appKey: {
    type: String,
    required: true,
  },
});

// 全语用自己的logo，其他都用unipus的默认logo
const currentLogo = computed(() => {
  if (props.appKey === 'quanyu') {
    return unipuslogo;
  }
  return unipuslogo;
});
</script>

<style lang="less" scoped>
// 桌面端
.desktop {
  width: 100%;
  height: 80px;
  line-height: 80px;
  background: #f2f6fc;
  .content {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .logo {
      width: 80px;
      height: 27px;
    }
    .text {
      font-size: 18px;
      font-weight: 500;
      margin-left: 4px;
      color: #0459c3;
      margin-top: 2px;
    }
  }
}
</style>
