<!--倒计时-->
<template>
  <span :style="{ fontSize: fontSize + 'px', color: 'red' }">{{ currentDate || timeText }}</span>
</template>

<script lang="ts" setup>
import { ref, PropType } from 'vue';
import { Modal, message } from 'ant-design-vue';

import { getOrderStatus, getContractStatus, Status } from '@/apis';
import { ORDER_STATUS } from '@/apis/common';
import { OrderInfo, ORDER_PAGE_STATUS } from '../utils/config';
import { getCurrentTime, handleResultStatus } from '../utils/method';

const props = defineProps({
  lastTime: {
    type: Number,
    required: true,
  },
  currentTime: {
    type: Number,
    required: true,
  },
  changeTimeoutStatus: {
    type: Function as PropType<(status: boolean) => void>,
    required: true,
  },
  fontSize: {
    type: Number,
    required: false,
    default: 20,
  },
  orderData: {
    type: (Object as PropType<OrderInfo>) || undefined,
    required: true,
  },
  changePaymentStatus: {
    type: Function as PropType<(status: ORDER_PAGE_STATUS) => void>,
    required: true,
  },
  timeText: {
    type: String,
    required: true,
  },
});

const currentDate = ref<string>('');
const { orderData, currentTime, lastTime, changePaymentStatus, changeTimeoutStatus } = props;

// 计算时间差
const timestampDiff = currentTime - Date.now();

const handleModal = () => {
  Modal.error({
    title: '支付超时',
    content: '订单支付超时，请重新下单',
    okText: '知道了',
    onOk: () => {
      handleResultStatus(orderData.failRedirect);
    },
  });
};

// 倒计时结束后，请求查询订单状态，根据支付状态跳转到指定页面
const getOrderStatusFn = async () => {
  const { data } = await getOrderStatus({
    orderNo: orderData.orderNo,
  });

  if (data) {
    if (data?.orderStatus === ORDER_STATUS.PAID) {
      message.success('订单已支付');
      changePaymentStatus(ORDER_PAGE_STATUS.PAID);
    } else if (data?.orderStatus === ORDER_STATUS.CANCELLED) {
      handleModal();
    } else {
      // 如果远端状态更改不及时，容错处理，再次请求一次
      getOrderStatusFn();
    }
  }
};

const updateCountdown = () => {
  const nowTimestamp = Date.now() + timestampDiff;
  const remainingTime = props.lastTime - nowTimestamp; // 计算剩余时间戳

  if (remainingTime < 1000) {
    currentDate.value = '00:00:00';
    clearInterval(timer);
    changeTimeoutStatus(true);

    // 区分普通订单还是签约
    orderData.orderNo !== 'null' ? getOrderStatusFn() : handleModal();
    return;
  }
  currentDate.value = getCurrentTime(remainingTime);
};

const timer = setInterval(updateCountdown, 1000);
</script>
