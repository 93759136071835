<template>
  <!-- 顶部订单状态 -->
  <div v-if="orderStatus !== ORDER_PAGE_STATUS.PAID">
    <Row justify="space-between" class="order-info">
      <Col>
        <div v-if="showOrderNo" class="order-id">订单号：{{ orderData?.orderNo }}</div>
        <div v-else-if="orderData?.contractNo" class="order-id">
          签约号：{{ orderData?.contractNo }}
        </div>
        <div class="order-last-time">
          请在<Countdown
            class="time"
            :orderData="orderData"
            :fontSize="20"
            :timeText="timeText"
            :lastTime="orderData?.timeleft"
            :currentTime="orderData?.currentTime"
            :changeTimeoutStatus="changeTimeoutStatus"
            :changePaymentStatus="changePaymentStatus"
          />
          内完成支付，否则订单会自动取消！
        </div>
      </Col>

      <Col class="amount-text">
        应支付：
        <span class="amount-unit">
          <span class="amount">{{ orderData?.price }}</span>
          <span>元</span>
        </span>
      </Col>
    </Row>
    <div class="hr"></div>
  </div>

  <!-- 中间内容 -->
  <div class="order-content">
    <!-- 未支付 -->
    <Unpaid
      v-if="orderStatus === ORDER_PAGE_STATUS.UNPAID"
      :orderData="orderData"
      :changePaymentStatus="changePaymentStatus"
    />

    <!-- 支付中 -->
    <InPayment
      v-else-if="orderStatus === ORDER_PAGE_STATUS.INPAYMENT"
      :orderData="orderData"
      :isTimeout="isTimeout"
      :currentChannel="currentChannel"
      :changePaymentStatus="changePaymentStatus"
    />

    <!-- 已支付 -->
    <Paid
      v-else-if="orderStatus === ORDER_PAGE_STATUS.PAID"
      :orderData="orderData"
      :changePaymentStatus="changePaymentStatus"
    />

    <div v-else>订单状态异常，请刷新重新查询订单状态</div>
  </div>
</template>
<script lang="ts" setup>
import { ref, PropType, computed } from 'vue';
import { Row, Col } from 'ant-design-vue';
import InPayment from './InPayment.vue';
import Paid from './Paid.vue';
import Unpaid from './Unpaid.vue';
import Countdown from '../widget/Countdown.vue';
import { ORDER_PAGE_STATUS, CHANNEL, OrderInfo } from '../utils/config';

const props = defineProps({
  orderData: {
    type: (Object as PropType<OrderInfo>) || undefined,
    required: true,
  },
  timeText: {
    type: String,
    required: true,
  },
});

const showOrderNo = computed(() => {
  return props.orderData?.orderNo && props.orderData.orderNo !== 'null';
});
const orderStatus = ref<ORDER_PAGE_STATUS>(ORDER_PAGE_STATUS.UNPAID);
const isTimeout = ref<boolean>(false);
const currentChannel = ref<CHANNEL>(CHANNEL.WECHAT);
const qrcodeUrl = ref<string>('');

// 切换页面的事件
const changePaymentStatus = (status: ORDER_PAGE_STATUS, channel?: CHANNEL, codeUrl?: string) => {
  orderStatus.value = status;
  channel ? (currentChannel.value = channel) : null;
  codeUrl ? (qrcodeUrl.value = codeUrl) : null;
};

// 订单超时状态，切换全局状态
const changeTimeoutStatus = (status: boolean) => {
  isTimeout.value = status;
};
</script>
<style lang="less" scope>
.order-info {
  width: 1100px;
  margin: 32px auto 0 auto;
  .order-id {
    height: 28px;
    line-height: 28px;
    font-size: 20px;
  }
  .order-last-time {
    height: 22px;
    line-height: 22px;
    font-size: 16px;
    margin-top: 16px;
    .time {
      margin: 0 10px;
    }
  }

  .amount-text {
    font-size: 16px;
  }
  .amount-unit {
    margin-left: 8px;
    color: red;
  }
  .amount {
    font-size: 40px;
    margin-right: 8px;
  }
}
.hr {
  width: 1200px;
  height: 4px;
  background: linear-gradient(to right, #6e8ad3, #a9d7f9);
  margin: 62px auto 40px auto;
}

.order-content {
  width: 1100px;
  margin: 0 auto;
}
</style>
