import { AxiosBase } from './base';
import { AxiosRequestBaseConfig, AxiosBaseOptions, AxiosErrorResult } from './types';
import { getSearchParams } from '@/utils/location';
// import { useOnline } from '@vueuse/core';
import { message as Message } from 'ant-design-vue';
import { v4 } from 'uuid';

const query = getSearchParams();

class MyAxios extends AxiosBase {
  constructor(options: AxiosBaseOptions) {
    const { ...axiosOptions } = options;
    super({
      ...axiosOptions,
    });
  }
}

// 个性化配置
const createApiClient = (): MyAxios => {
  return new MyAxios({
    baseURL: '/api',
    timeout: 3000,
    withCredentials: true,
    getExtraHeaders: () => ({
      'Content-Type': 'application/json',
      'access-token': query['userId'],
      appKey: query['appKey'] || 'aigc',
      ssoId: query['ssoId'],
      requestId: v4(),
      uid: '0',
    }),
    // 请求前可以做某些操作
    // onBeforeStart: (config: AxiosRequestBaseConfig) => {
    //   console.log('请求前', config);
    // },
    // 可连接日志系统，进行日志记录 centry
    // onLogger: (config: AxiosRequestBaseConfig, info: any, success: boolean) => {
    //   console.log(
    //     'log-----',
    //     config,
    //     'log-----',
    //     'info:',
    //     info,
    //     'success',
    //     success,
    //   );
    // },
    // 处理 error 场景
    onError: (err: AxiosErrorResult) => {
      // const online = useOnline();
      // if (!online.value) {
      //   Message.error('怎么没网络');
      // }
      // 重新登录
      if (err.code === 401) {
        Message.error('登录失效');
      }
    },
  });
};

export const Axios = createApiClient();
