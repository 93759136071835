<template>
  <PCHeader v-if="hasHeader && platform === ISUBMIT_SOURCE.PC" :appKey="appKey" />
  <H5Header v-if="hasHeader && platform === ISUBMIT_SOURCE.H5" :appKey="appKey" />
  <ErrorPage v-if="errorText" :errorText="errorText" :platform="platform" />

  <template v-else>
    <component
      :is="currentComponent"
      v-if="orderInfo"
      :orderData="orderInfo"
      :timeText="timeText"
    />
  </template>
  <!-- <Button @click="create">创建订单</Button>
  <div>新订单号：{{ newOrderText || orderNo }}</div> -->
  <!-- <Button @click="create">签约</Button>
  <div>新订单号：{{ newOrderText || orderNo }}</div> -->
  <!-- contractSign -->
</template>
<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import { message, Button } from 'ant-design-vue';
import { useOnline } from '@vueuse/core';
import Desktop from './PCPaymentPage/Desktop.vue';
import Mobile from './H5PaymentPage/Mobile.vue';
import ErrorPage from './widget/ErrorPage.vue';
import PCHeader from './PCPaymentPage/PCHeader.vue';
import H5Header from './H5PaymentPage/H5Header.vue';
import { ISUBMIT_SOURCE, ORDER_STATUS } from '@/apis/common';
import {
  getOrderStatus,
  getUnpaidInfo,
  createOrder,
  contractSign,
  getContractStatus,
  Status,
} from '@/apis';
import { handleUrlParams, getCurrentTime, handleResultStatus } from './utils/method';

const props = defineProps({
  hasHeader: {
    type: Boolean,
    required: true,
    default: true,
  },
  orderNo: {
    type: String,
    required: true,
  },
  contractNo: {
    type: String,
    required: true,
  },
  platform: {
    type: String,
    required: true,
    default: ISUBMIT_SOURCE.PC,
  },
  appKey: {
    type: String,
    required: true,
  },
  failRedirect: {
    type: String,
    required: true,
  },
  succRedirect: {
    type: String,
    required: true,
  },
});

const newOrderText = ref<string>();
const errorText = ref<string>('');
const orderInfo = ref();
const timeText = ref<string>('');
const { orderNo, contractNo, platform, succRedirect, failRedirect } = props;

const currentComponent = computed(() => {
  if (props.platform === ISUBMIT_SOURCE.PC) {
    return Desktop;
  }
  return Mobile;
});

const updateCountdown = (lastTime: number, currentTime: number) => {
  const targetTime = Number(lastTime);

  const remainingTime = targetTime - currentTime; // 计算剩余时间戳

  return getCurrentTime(remainingTime);
};

// 获取待支付信息展示
const getOrderInfo = async () => {
  const { data } = await getUnpaidInfo({
    orderNo,
    contractNo,
    submitSource: platform,
    succRedirect,
    failRedirect,
  });

  const currentInfo = data && handleUrlParams(data);
  if (currentInfo?.timeleft && currentInfo?.currentTime) {
    timeText.value = updateCountdown(currentInfo?.timeleft, currentInfo?.currentTime);
  }
  orderInfo.value = data && currentInfo;
};

// 请求查询签约状态
const getContractStatusFn = async () => {
  try {
    const { data } = await getContractStatus({
      contractNo,
    });
    if (data) {
      if (data?.status === Status.SIGNED || data?.status === Status.EFFECTIVE) {
        message.success('已成功签约');
        handleResultStatus(succRedirect);
      } else if (data?.status === Status.PRE_SIGNING) {
        getOrderInfo();
      } else if (
        data?.status === Status.UN_SUBSCRIBED ||
        data.status === Status.CONTRACT_TERMINATED
      ) {
        message.info('当前签约流程已失效，请返回订单页重新发起签约');
        setTimeout(() => {
          handleResultStatus(failRedirect);
        }, 100);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

// 请求查询订单状态，根据支付状态跳转到指定页面
const getOrderStatusFn = async () => {
  try {
    const { data } = await getOrderStatus({
      orderNo,
    });
    if (data) {
      if (data?.orderStatus === ORDER_STATUS.PAID) {
        message.success('订单已支付');
        handleResultStatus(succRedirect);
      } else if (data?.orderStatus === ORDER_STATUS.CANCELLED) {
        message.info('订单已取消');
        handleResultStatus(failRedirect);
      } else {
        getOrderInfo();
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

// 测试完删除
// const create = async () => {
//   const { data } = await contractSign();

//   if (data) {
//     newOrderText.value = data;
//   }
// };

onMounted(() => {
  const online = useOnline();
  if (!online.value) {
    return (errorText.value = '网络连接失败');
  }
  if (!currentComponent.value) {
    return (errorText.value = '接入方式异常');
  }
  contractNo && getContractStatusFn();
  orderNo && getOrderStatusFn();
});
</script>
