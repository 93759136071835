import { Axios } from '@/request/axios';
import { ResponseResult } from '@/request/types';
import { ISUBMIT_SOURCE } from './common';

interface IPayElementRequest {
  submitSource: ISUBMIT_SOURCE;
  channel: number;
  orderNo: string;
  terminalIp?: string;
  uid?: string;
  wxOpenId?: string;
  returnUrl?: string;
}

interface CHANNEL_FACTOR {
  appId: string;
  codeUrl: string;
  nonceStr: string;
  packageInfo: string;
  partnerId: string;
  prepayid: string;
  sign: string;
  timestamp: string;
}

enum PAY_CURRENCY {
  CNY,
  UCOIN,
}

interface IPayElementResponse {
  payCurrency: PAY_CURRENCY;
  price: number;
  rmbPrice: number;
  channelFactor: CHANNEL_FACTOR;
}

// 获取支付要素，展示二维码 / 跳转链接
const getOrderPayElement = async (
  params: IPayElementRequest,
): Promise<ResponseResult<IPayElementResponse>> => {
  const data: ResponseResult<IPayElementResponse> = await Axios.post(`/order-center/pay/factor`, {
    ...params,
  });
  return data;
};

export { getOrderPayElement };
