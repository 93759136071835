<!--顶部header-->
<template>
  <div class="mobile">
    <Row justify="center" class="content">
      <Col><img :src="currentLogo" class="logo" /></Col>
      <Col> <div class="text">收银台</div></Col>
    </Row>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Row, Col } from 'ant-design-vue';
import unipuslogo from '@/assets/img/logo.png';

const props = defineProps({
  appKey: {
    type: String,
    required: true,
  },
});

// 全语用自己的logo，其他都用unipus的默认logo
const currentLogo = computed(() => {
  if (props.appKey === 'quanyu') {
    return unipuslogo;
  }
  return unipuslogo;
});
</script>

<style lang="less" scoped>
// 移动端
.mobile {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid #eeeeee;

  .content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    .logo {
      width: 61px;
      height: 20px;
    }
    .text {
      font-size: 12px;
      font-weight: 500;
      margin-left: 10px;
      color: #0459c3;
      margin-top: 2px;
    }
  }
}
</style>
