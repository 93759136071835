<template>
  <div class="success-info">
    <img src="@/assets/img/success.png" class="icon" />
    <div class="message">支付成功</div>
    <div class="order-info">
      <div>订单号：{{ orderData.orderNo }}</div>

      <div class="price">支付金额：{{ orderData.price }}</div>
    </div>
    <Button type="primary" class="complete-btn" @click="paid"> 支付完成 </Button>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { Button } from 'ant-design-vue';
import { ORDER_PAGE_STATUS, OrderInfo } from '../utils/config';
import { handleResultStatus } from '../utils/method';

const props = defineProps({
  orderData: {
    type: Object as PropType<OrderInfo>,
    required: true,
  },
  changePaymentStatus: {
    type: Function as PropType<(status: ORDER_PAGE_STATUS) => void>,
    required: true,
  },
});

// 订单已成功支付
const paid = () => {
  handleResultStatus(props.orderData.succRedirect);
};
</script>
<style lang="less" scoped>
.success-info {
  text-align: center;
  margin-top: 100px;
}
.icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.message {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #656665;
  line-height: 28px;
  margin-bottom: 102px;
}

.order-info {
  width: 430px;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #303133;
  text-align: left;
  .price {
    height: 28px;
    line-height: 28px;
    margin-top: 20px;
  }
}
.complete-btn {
  margin-top: 100px;
  width: 180px;
  height: 50px;
  background: #527de2;
  border-radius: 4px;
  font-size: 16px;
}
</style>
