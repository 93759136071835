/**
 * 获取url的参数，只建议入口文件引用，其他组件不推荐使用
 * @returns
 */
export const getSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const query: Record<string, string> = {};
  for (const [key, value] of searchParams.entries()) {
    query[key] = value;
  }
  return query;
};
