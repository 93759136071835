<template>
  <Menu>
    <Menu.Item @click="toProductionList"><ArrowLeftOutlined /> 返回产品列表</Menu.Item>
  </Menu>

  <Row justify="center"><h1>智能对话产品购买</h1></Row>
  <div class="produtionlist">
    <div
      v-for="(item, index) in productionList"
      :key="index"
      class="list"
      @click="handleChecked(item.id)"
    >
      <h3>{{ item.name }}</h3>

      <Row justify="center">
        <h1>¥ {{ item.price }} <span :style="{ fontSize: '16px', color: '#666666' }">/年</span></h1>
      </Row>

      <Row class="agreement" justify="center">
        <Checkbox v-model:checked="checked" />
        <span class="with">
          已阅读并同意
          <a href="https://app.unipus.cn/aioral/member.html" target="_blank" rel="noreferrer">
            《外研AIGC会员协议》
          </a>
        </span>
      </Row>
      <Row justify="center">
        <Button class="purchase" type="primary" @click="handlePurchase(item.id)"> 立即购买 </Button>
      </Row>

      <div v-for="(value, i) in item.conditions" :key="i" class="channel">
        <div class="strip">
          <h4 class="text">
            <CheckCircleOutlined :style="{ fontSize: '16px', color: '#999999' }" />
            {{ value.label }}
          </h4>
        </div>
      </div>
    </div>
  </div>

  <hr />
  <Row class="contact" justify="center">
    <div v-for="(item, index) in contactList" :key="index" class="content">
      <div>{{ item.label }}</div>
      <div>{{ item.value }}</div>
    </div>
  </Row>
  <Row justify="center">
    <Col>公司名称:北京外研在线数字科技有限公司</Col>
    <Col offset="2">联系地址:北京市海淀区中关村大街18号B座20层</Col>
  </Row>
</template>

<script lang="ts" setup>
import { ref, PropType } from 'vue';
import { Button, Checkbox, Row, message, Col, Menu } from 'ant-design-vue';
import { CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue';
import { createOrder } from '../../apis';
import { productionList, contactList, ORDER_PAGE_STATUS } from './config';

const props = defineProps({
  changePaymentStatus: {
    type: Function as PropType<(status: ORDER_PAGE_STATUS) => void>,
    required: true,
  },
});
const checked = ref(true);

const checkedBox = ref(1);

const handleChecked = (id: number) => {
  checkedBox.value = id;
};

const toProductionList = () => {
  props.changePaymentStatus(ORDER_PAGE_STATUS.DIALOGUE);
};

const handlePurchase = async (type: number) => {
  if (!checked.value) {
    return message.error('请阅读并同意协议');
  }
  const price = type === 1 ? 48000 : 68000;

  const { data } = await createOrder({
    goodsPrice: price,
    payableAmount: price,
    actuallyAmount: price,
    orderDetails: [{ goodsId: 10086, price: price, goodsName: '中台支付测试商品' }],
  });
  if (data) {
    const env = process.env.APP_ENV;
    const url = env === 'pro' ? `https://pay.unipus.cn/` : `https://pay-test.unipus.cn/`;
    const params = `?platform=h5&orderNo=${data}&appKey=aigc&userId=123123123&ssoId=123&succRedirect=${url}&failRedirect=${url}`;

    window.location.href = `${url}${params}`;
  }
};
</script>
<style scoped lang="less">
.produtionlist {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.list {
  width: 280px;
  height: 380px;
  margin: 10px auto;
  padding-top: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 0 6px 3px rgba(2, 2, 2, 0.2);
  cursor: pointer;

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    text-align: center;
  }

  .strip {
    display: flex;
    align-items: center;
    padding-top: 11px;
    padding-left: 11%;

    img {
      display: block;
      width: 16px;
      height: 16px;
      margin-top: -6px;
      margin-right: 10px;
    }

    .text {
      color: rgb(0 0 0 / 85%);
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.agreement {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 40px;
  color: rgb(0 0 0 / 40%);
  font-weight: 500;
  font-size: 9px;
  line-height: 24px;
  text-align: left;

  .with {
    margin-left: 5px;

    a {
      cursor: pointer;
    }
  }
}
.purchase {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 35px;
  margin: 0 auto;
  border-radius: 4px;
}

.contact {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
  .content {
    display: flex;
    width: 200px;
    height: 150px;
    justify-content: start;
    flex-direction: column;
    text-align: center;
    line-height: 60px;
    font-size: 16px;
    border: 1px solid #ccc;

    &:nth-child(1) {
      border-right: 1px solid white;
    }
    &:nth-child(2) {
      border-right: 1px solid white;
    }
  }
}
</style>
