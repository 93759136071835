enum ORDER_PAGE_STATUS {
  DIALOGUE,
  MARKET,
}

interface IConditionItem {
  label: string;
}
const conditionList: IConditionItem[] = [
  {
    label: '智能对话Pro流式应答500次/月',
  },
  {
    label: '支持调用chat3.5智能对话模型',
  },
  {
    label: '主版本更新功能随时更新',
  },
];
const conditionList2: IConditionItem[] = [
  {
    label: '智能对话Pro流式应答7500次/年',
  },
  {
    label: '支持所有智能对话模型选择',
  },
  {
    label: '专属会员快速响应',
  },
];

const productionList = [
  {
    id: 1,
    name: '基础版',
    price: '480',
    // subPrice: '每天大约2.2元',
    conditions: conditionList,
  },
  {
    id: 2,
    name: '高级版',
    price: '680元',
    // subPrice: '每天大约1.6元',
    conditions: conditionList2,
  },
];

const contactList = [
  {
    label: '联系人',
    value: '外研在线客服',
  },
  // {
  //   label: '公司名称',
  //   value: '北京外研在线数字科技有限公司',
  // },
  // {
  //   label: '联系地址',
  //   value: '北京市海淀区中关村大街18号B座20层联系',
  // },
  {
    label: '联系邮箱',
    value: 'aiservice@unipus.cn',
  },
  {
    label: '客服热线',
    value: '400-898-7008',
  },
];

export { conditionList, productionList, contactList, ORDER_PAGE_STATUS };
