import { AxiosRequestConfig } from 'axios';

// 可以根据环境的不同处理不同前缀
type AppEnvironment = 'dev' | 'test' | 'production';

// 通用返回的配置
interface ResponseResult<T> {
  code: number;
  data?: T;
  message: string;
}

// 通用请求logger的配置
interface AxiosLoggerInfo {
  code: number;
  message: string;
  requestTime: number;
  responseStatus: number;
}

// 报错后返回结果
interface ErrorResult<T> extends Error, ResponseResult<T> {}

// 自定义header，和业务有关联
interface UserHeaders {
  appKey: string;
  'access-token': string;
  ssoId: string;
  [key: string]: string;
}

// 通用的header
interface RequestHeaders extends Partial<UserHeaders> {
  'request-timestamp': string;
  // 'request-traceid': string;
}

// request 请求参数配置
interface AxiosRequestBaseConfig extends AxiosRequestConfig {
  /**
   * @description 是否匿名请求
   * @description 如果此项为真那么 getExtraHeaders 无效
   */
  anonymous?: boolean;
  /**
   * @description 是否隐藏错误信息提示
   * @default false
   */
  hideError?: boolean;
}

// 请求的错误配置
interface AxiosErrorResult<T = unknown> extends ErrorResult<T> {
  axiosConfig: AxiosRequestBaseConfig;
}

// response 返回结果的配置
interface AxiosResponseResult<T = unknown> extends ResponseResult<T> {
  axiosConfig: AxiosRequestBaseConfig;
}

// 基础的配置options
interface AxiosBaseOptions extends AxiosRequestConfig {
  /**
   * @description 额外请求头
   */
  getExtraHeaders: () => Partial<UserHeaders>;
  /**
   * @description 是否屏蔽 onError 副作用执行
   * @default false
   */
  hideError?: boolean;
  /**
   * @description 请求开始前
   */
  onBeforeStart?: (config: AxiosRequestBaseConfig) => void;
  /**
   * @description 请求错误副作用
   * @description 如果 `hideError = true` 那么此项无效
   */
  onError?: (error: AxiosErrorResult) => void;
  /**
   * @description 请求日志副作用（不管是否请求成功）
   * @description 始终最后触发
   */
  onLogger?: (config: AxiosRequestBaseConfig, info: AxiosLoggerInfo) => void;
}

/**
 * 当前后端返回code为 0 时，代表请求返回成功
 * 当返回code 值为非 0 时，则为失败
 *
 * 失败可能有多种失败原因，不同业务定义不同，这里不能一一枚举，则假设一个10为默认值，当后端返回code值缺失时 or 网络异常时，补充值为10
 */
const DEFAULT_SUCCESS_CODE = 0;
const DEFAULT_ERROR_CODE = 10; // 假设值

const FORBIDDEN_RESPONSE_TYPES: AxiosRequestConfig['responseType'][] = [
  'arraybuffer',
  'blob',
  'document',
  'stream',
  'text',
];

export {
  AppEnvironment,
  ResponseResult,
  ErrorResult,
  RequestHeaders,
  AxiosLoggerInfo,
  UserHeaders,
  AxiosRequestBaseConfig,
  AxiosBaseOptions,
  AxiosResponseResult,
  AxiosErrorResult,
  DEFAULT_SUCCESS_CODE,
  DEFAULT_ERROR_CODE,
  FORBIDDEN_RESPONSE_TYPES,
};
