import { Axios } from '@/request/axios';
import { ResponseResult } from '@/request/types';
import { ORDER_TYPE } from './common';

interface IOrderDetail {
  goodsId: number;
  price: number;
  goodsName: string;
}

interface ICreateRequest {
  orderDetails: IOrderDetail[];
  type: ORDER_TYPE;
  goodsPrice: number;
  buyerName: string;
  submitSource: string;
  userId: number;
  submitSence: string;
  payableAmount: number;
  actuallyAmount: number;
  ssoId: string;
}

const mock_params: ICreateRequest = {
  orderDetails: [{ goodsId: 10086, price: 1, goodsName: '中台支付测试商品' }],
  type: ORDER_TYPE.STANDARD,
  goodsPrice: 1,
  buyerName: 'musestest',
  submitSource: '04',
  userId: 123123123,
  submitSence: 'submitsence',
  payableAmount: 1,
  actuallyAmount: 1,
  ssoId: '123',
};

// 创建订单 （当前系统暂时用不到此接口，只在测试初期使用，prod 环境是业务来创建订单，并跳转到当前系统）
const createOrder = async (price: {
  goodsPrice?: number;
  payableAmount?: number;
  actuallyAmount?: number;
  orderDetails?: [{ goodsId: number; price: number; goodsName: string }];
}) => {
  const data: ResponseResult<string> = await Axios.post(`/order/create`, {
    ...mock_params,
    ...price,
  });

  return data;
};

export { createOrder };
