<template>
  <template v-if="currentChannelList.length">
    <div class="title">支付方式：</div>
    <Row>
      <div v-for="(item, index) in currentChannelList" :key="index" class="channel">
        <Col>
          <div class="channel-item" @click="toPay(item.id)">
            <img :src="item?.img" class="pay-icon" />
            {{ item.name }}
          </div>
        </Col>
      </div>
    </Row>
  </template>

  <template v-else>当前商户没有可支持的支付方式，请联系商户开通</template>
</template>

<script lang="ts" setup>
import { ref, onMounted, PropType } from 'vue';
import { Row, Col } from 'ant-design-vue';
import { channelList, IChannelItem, CHANNEL, ORDER_PAGE_STATUS, OrderInfo } from '../utils/config';

const props = defineProps({
  orderData: {
    type: Object as PropType<OrderInfo>,
    required: true,
  },

  changePaymentStatus: {
    type: Function as PropType<(status: ORDER_PAGE_STATUS, channelId: CHANNEL) => void>,
    required: true,
  },
});

const currentChannelList = ref<IChannelItem[]>([]);

// 去支付
const toPay = (channelId: CHANNEL) => {
  props.changePaymentStatus(ORDER_PAGE_STATUS.INPAYMENT, channelId);
};

onMounted(() => {
  // 组装渠道列表
  currentChannelList.value = channelList.filter((item1) => {
    return props.orderData?.channel?.some((item2) => item2.channelCode === item1.id);
  });
});
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
}
.channel {
  margin-top: 24px;
  .channel-item {
    width: 530px;
    height: 130px;
    line-height: 130px;
    cursor: pointer;
    box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.06);
    border: 1px solid #ebeef5;
    color: #303133;
    font-size: 20px;

    .pay-icon {
      width: 50px;
      height: 50px;
      margin: 0 30px 0 50px;
    }
  }
  :hover {
    background-color: #ebeef5;
  }

  &:nth-child(2n) {
    margin-left: 30px;
  }
}
</style>
