import { Axios } from '@/request/axios';
import { ResponseResult } from '@/request/types';
import { MEDIA_TYPE, ORDER_TYPE, PAYMENT_STATUS, ORDER_STATUS } from './common';

interface IRequestOrderStatus {
  orderNo: string;
  scenes?: string;
  param?: string;
}

interface IGoods {
  additionalInfo: string;
  goodsChannel: string;
  goodsId: number;
  goodsImage: string;
  goodsName: string;
  goodsNum: number;
  mediaType: MEDIA_TYPE;
  originPrice: number;
  resCount: number;
  resId: string;
  resType: number;
  salePrice: number;
  subTitle: string;
  validityDate: Date;
  validityDay: number;
}

interface IResponseOrderStatus {
  actuallyAmount: number;
  appKey: string;
  buyerName: string;
  closeTime: Date;
  costIntegral: number;
  createTime: Date;
  goodsPrice: number;
  orderNo: string;
  orderStatus: ORDER_STATUS;
  payableAmount: number;
  paymentStatus: PAYMENT_STATUS;
  splitType: string;
  ssoId: string;
  type: ORDER_TYPE;
  userId: number;
  goods: IGoods;
}

// 获取订单状态
const getOrderStatus = async (
  params: IRequestOrderStatus,
): Promise<ResponseResult<IResponseOrderStatus>> => {
  const data: ResponseResult<IResponseOrderStatus> = await Axios.get('/order-center/query', params);
  return data;
};

export { getOrderStatus };
