<template>
  <div class="box">
    <Tabs>
      <TabPane key="1" tab="智能对话">
        <iframe
          title="智能对话"
          width="100%"
          height="630px"
          :src="url"
          allow="microphone;camera;midi;encrypted-media;"
        />
      </TabPane>
      <template #rightExtra>
        <a :style="{ color: 'red' }" @click="handleLink">产品付费</a>
      </template>
    </Tabs>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { Tabs } from 'ant-design-vue';
import { ORDER_PAGE_STATUS } from './config';

const props = defineProps({
  changePaymentStatus: {
    type: Function as PropType<(status: ORDER_PAGE_STATUS) => void>,
    required: true,
  },
});

const { TabPane } = Tabs;
const url =
  'https://chat-aigc.unipus.cn?imgsec=http%3A%2F%2Fucloudqn.unipus.cn%2Ficon2.png&openId=2c693f1178d5d348166c715fdc64628c&type=2&token=eyJhbGciOiJSUzI1NiIsImtpZCI6InVuaXB1cy1zc28ifQ.eyJqdGkiOiItbDJJY012Wk1pYk9jTW1NU1ZWc2J3IiwiaWF0IjoxNzAyODc4MzU4LCJleHAiOjE3MDI4ODU1NTgsIm5iZiI6MTcwMjg3ODI5OCwiaXNzIjoiaHR0cHM6Ly9zc28udW5pcHVzLmNuIiwiYXVkIjoiY24udW5pcHVzLnNzby5qd3QiLCJzdWIiOiIxMDAwMDAwIiwib3BlbklkIjoiMmM2OTNmMTE3OGQ1ZDM0ODE2NmM3MTVmZGM2NDYyOGMiLCJ1c2VybmFtZSI6IjE1MjEwNjY3NjU4Iiwibmlja25hbWUiOiLkuZ3mnIgiLCJwaG9uZSI6IjE1MjEwNjY3NjU4IiwiZW1haWwiOiJsaXhpYW9xaW5nQHVuaXB1cy5jbiIsInRpbWVzdGFtcCI6MTcwMjg3ODM1OCwia2V5IjoidW5pcHVzLXNzbyJ9.VN3LeBlv4PieuGxj_jzaez3pEh1nqZQGhs7nTa0xvQmQM9HmPyc9ht9Zvr-ulHmf5rmZmAoA_oFxAyBq3lyYBIVYtPAVnnTLcCxKR9ogAnnCFlNB0HFrDup33umbR4DvkVXDL-dwuP3-DxF4ukX0zeKhQHdBbIc7Np3Jc-erRS3HGvYPGhacoHgWkhbKLQfLGxqgF5QH879010jvDdpmkHN7XYLR4OBTKRLIRbOJWZgty_KZ9McSO4EkM9gULz5hsvqMrGJq6yoAzMrWcX3GwWwh8U0pCJA2su6JyM3QmRnntvEiWs2ZMxCYcTFWqx2_DrkBVuffJfxzGb6h3uHL6A';

const handleLink = () => {
  props.changePaymentStatus(ORDER_PAGE_STATUS.MARKET);
};
</script>

<style scope lang="less">
.box {
  height: 100%;
  margin-left: 20px;
  margin-right: 30px;
}
.market {
  cursor: pointer;
}
</style>
